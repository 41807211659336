
import { mixCt } from "@/mixins/mixCt";
import { mixBtras } from "@/mixins/mixBtras";
import storeR from '@/stores/modulos/storeR.js';

export var mixR = {
  mixins: [mixCt, mixBtras],
  props: {
    storeRaiz: { type: String, default: "" },             // raiz del store
    masterStore: { type: String, default: '' },           // nombre del store asociado
    masterRecordField: { type: String, default: 'id' },   // campo relacionado con el Maestro
    storeAux: { type: String, default: "" },              // store auxiliar
    recordAux: { type: Object, default: null },           // record auxiliar
  },

  data() {
    return {
      raiz: '',                        // store raiz name para pasar
      storeName: '',                   // store name particular (storeRaiz + stIni.api + (index si storeRaiz== '')
      ct: 0,                           // array de controles
      apiArgs: {},                     // objeto donde están definidas las llamadas a la API
      dataAux: {},                     //
      //
      btExtCfg: null,                  // (design, btnsAccion). Configuración botonera Extra
      autoLoad: '',                    // acción API de autocarga

      componenteDinamico: null,        // nombre del Mto dinámico */
      loaded: false,                   // switch que indica si han terminado las cargas asíncronas para cargar el template
    };
  },

  async created() {
    console.log(`DEV ${this.stIni.api} ********************** (created)`);

    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();

    // inicializo apiArgs
    this.iniDataApi();

    // inicializo variables Btra según tipo de componente
    this.iniBtra();

    // registro Store
    this.registerStore(storeR);

    // inicializo store (stIni)
    await this.iniStore();

    // paso schema a controles
    this.ct = JSON.parse(JSON.stringify(this.sch));

    // inicializo datos particulares
    await this.iniDataParticular();

    // funciones a ejecutar una vez cargado totalmente el componente
    this.loadedComponent();
  },


  methods: {

    // inicializo Data Generales
    iniDataMixin() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataMixin)");

      //
      this.apiArgs = {
        sch: ["report", 'getSchema', {}, this.stIni.api],
        get: ["report", 'recordsGet', {}, this.stIni.api],
        data: ["report", 'changeData', {}, this.stIni.api],
        rep: ["report", 'report', { ctrls: {}, tip:'' }, this.stIni.api],
      }
    },


    // override de apiArgs en particular en caso que sea necesario
    iniDataApi() {},


    // inicializo Btra y variables según tipo de componente
    iniBtra() {
      this.btExtCfg = JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
    },


    // inicializo datos particulares
    iniDataParticular() {},


    // entro cuando el componente se ha cargado
    async loadedComponent() {

      // API call para obtener arrays adicionales para el schema
      // guarda records en store (reactivo en grid con computed:records)
      await this.stRecordsGet();

      // indico que el componente ya se ha cargado
      this.loaded= true;

      // emito evento por loaded por si lo recoge alguna funcion en el padre
      // por si hay que realizar alguna operación después de la carga del componente
      this.$emit('loaded', this.storeName);
    },


    // ---- Acciones Store ---------------------------------------------------------
    async stRecordsGet() {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'args' de apiArgs
      let param = { apiArg: this.apiArgs.get, args: {} };
      // actualiza schema y arrays adicionales
      await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore });
      //al finalizar recogidad de datos
      this.stRecordsGetFin();
    },

    stRecordsGetFin() {
    },

    // RECIBO EVENTOS BOTONERA tanto de Mto Row como Extra
    execAccion(evt) {
      console.log("DEV execAccion " + this.stIni.api + ":", evt);

      // ejecutamos la función de la acción (si existe)
      // Si viene por ejemplo (0=ver, 1=nuevo) tenemos una por defecto
      if (!this.$isFunction(this[evt.accion])) return;
      return this[evt.accion](evt);
    },


    // entra al seleccionar un informe
    changeInf(data) {
      console.log('**change_inf: ', data);
      var elem = data[0];

      // actualiza value del control 'inf' al índice del informe seleccionado
      this.$set(this.ct.inf, 2, elem.d);

      // si el informe seleccionado tiene un array de orden, modifica el select de orden
      if (typeof elem.o !== "undefined") {
        this.ord = this.recordsRead[1][elem.o];
        this.$set(this.ct.ord, 2, "0");
      }

      this.changeInfFin();
    },

    //
    changeInfFin() {},

    // Cambio Filtro Datos, nuevos arrays/combos, Ejemplo leer filtros segun el año
    async changeData() {},

    //
    async report(tip) {
      // servidor 0=server,1=adm
      let srv=1;

      // llamada API
      let param= { apiArg:this.apiArgs.rep, args:{ tip, ctrls:this.ctrl2record(this.ct, {}, false) }, srv};
      console.log('**report: ', param);
      let apiResult = await this.$store.dispatch("apiCall", param);
      console.log('apiResult Acepto Estado: ', apiResult);

      //
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      let url=(param.srv===1 ? this.$store.state.G.inf_tmp : this.$store.state.G.inf_tmpNV) + apiResult.r[0];
      window.open(url, "_blank");
    },

    // RECIBO EVENTOS CABECERA (ahora mismo solo se gestiona el único que evento que tiene, que es el de 'cerrar')
    eventHeader(evt) {
      console.log("DEV eventHeader " + this.stIni.api + ":", evt);

      // si no se recibe storeRaiz (significa que viene del menú) se hace un router al raiz
      // si se recibe, se emite el evento al padre
      if (this.storeRaiz == "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }

      // emito evento de cierre al padre
      this.$emit('onEvent', { accion: 6, origen: 'Report' });
    },


    // muestro informe en pdf
    verPdf() {
      this.showReport("pdf");
    },

    // muestro informe en excel
    verExcel() {
      this.showReport("xls");
    },


    // muestro informe
    async showReport(tip) {
      // compruebo si hay seleccionado un Informe
      if (!this.ct.inf[2]) {
        this.$root.$alert.open('Debes seleccionar un informe', 'error');
        return;
      }

      this.report(tip);
    }



  },

  //
  computed: {

    // schema de controles del filtro de búsqueda
    sch() {
      return this.$store.state[this.storeName].sch;
    },

    // array de registros del Grid
    records() {
      return this.$store.state[this.storeName].records;
    },

    // array con todos los registros (sólo lectura)
    recordsRead() {
      return this.$store.state[this.storeName].recordsRead;
    },

    // permisos extra
    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    },

  }
};
